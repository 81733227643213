import { render, staticRenderFns } from "./Estrela.vue?vue&type=template&id=059b90de&scoped=true&"
import script from "./Estrela.vue?vue&type=script&lang=js&"
export * from "./Estrela.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "059b90de",
  null
  
)

export default component.exports